$('.small-card-slider').slick({
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 2000,
    speed: 800,
    slidesToShow: 9,
    slidesToScroll: 9,
    responsive: [{
            breakpoint: 1024,
            settings: {
                arrows: false,
                slidesToShow: 9,
                slidesToScroll: 9,
                infinite: true,
                autoplay: false,
                autoplaySpeed: 2000,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                rows: 2,
                autoplay: false,
                autoplaySpeed: 2000,
                dots: true,
                arrows: false,
                slidesToShow: 3,
                slidesToScroll: 3
            }
        },
        {
            breakpoint: 480,
            settings: {
                rows: 2,
                autoplay: false,
                autoplaySpeed: 2000,
                dots: true,
                arrows: false,
                slidesToShow: 3,
                slidesToScroll: 3
            }
        }

    ]
});

$('.large-card-slide').slick({
    dots: true,
    arrows: false,
    infinite: false,
    centerMode: false,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 800,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [{
            breakpoint: 1024,
            settings: {
                dots: true,
                arrows: false,
                autoplay: true,
                autoplaySpeed: 2000,
                slidesToShow: 2,
                slidesToScroll: 1,
                centerMode: false,
                infinite: false,
            }
        },
        {
            breakpoint: 600,
            settings: {
                dots: false,
                arrows: false,
                autoplay: true,
                autoplaySpeed: 2000,
                centerMode: false,
                slidesToShow: 1.1,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 480,
            settings: {
                dots: false,
                autoplay: true,
                autoplaySpeed: 2000,
                arrows: false,
                centerMode: false,
                slidesToShow: 1.1,
                slidesToScroll: 1
            }
        }

    ]
});

$('.large-card-slide-2').slick({
    dots: false,
    arrows: false,
    infinite: false,
    autoplay: false,
    autoplaySpeed: 2000,
    speed: 800,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [{
            breakpoint: 1024,
            settings: {
                arrows: false,
                autoplay: false,
                autoplaySpeed: 2000,
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: false,
                dots: false
            }
        },
        {
            breakpoint: 600,
            settings: {
                dots: false,
                autoplay: false,
                autoplaySpeed: 2000,
                arrows: false,
                slidesToShow: 1.1,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 480,
            settings: {
                dots: false,
                autoplay: false,
                autoplaySpeed: 2000,
                arrows: false,
                slidesToShow: 1.1,
                slidesToScroll: 1
            }
        }

    ]
});

$('.login-slider').slick({
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [{
            breakpoint: 1024,
            settings: {
                arrows: false,
                autoplay: true,
                autoplaySpeed: 2000,
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                dots: true,
                autoplay: true,
                autoplaySpeed: 2000,
                arrows: false,
                slidesToShow: 1,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 480,
            settings: {
                dots: true,
                autoplay: true,
                autoplaySpeed: 2000,
                arrows: false,
                slidesToShow: 2,
                slidesToScroll: 2
            }
        }

    ]
});

$('.video-slider').slick({
    dots: false,
    arrows: false,
    infinite: false,
    autoplay: false,
    autoplaySpeed: 2000,
    speed: 800,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [{
            breakpoint: 1024,
            settings: {
                arrows: false,
                autoplay: false,
                autoplaySpeed: 2000,
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: false,
                dots: false
            }
        },
        {
            breakpoint: 600,
            settings: {
                dots: false,
                autoplay: false,
                autoplaySpeed: 2000,
                arrows: false,
                slidesToShow: 1.3,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 480,
            settings: {
                dots: false,
                autoplay: false,
                autoplaySpeed: 2000,
                arrows: false,
                slidesToShow: 1.3,
                slidesToScroll: 1
            }
        }

    ]
});

$('.physiotherapy-services-slider').slick({
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 800,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [{
            breakpoint: 1024,
            settings: {
                arrows: false,
                autoplay: true,
                autoplaySpeed: 2000,
                slidesToShow: 4,
                slidesToScroll: 4,
                infinite: true,
                dots: false
            }
        },
        {
            breakpoint: 600,
            settings: {
                dots: true,
                autoplay: true,
                autoplaySpeed: 2000,
                arrows: false,
                slidesToShow: 1.2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 480,
            settings: {
                dots: true,
                autoplay: true,
                autoplaySpeed: 2000,
                arrows: false,
                slidesToShow: 1.2,
                slidesToScroll: 1
            }
        }

    ]
});

$('.package-card-slider').slick({
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 2000,
    speed: 800,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [{
            breakpoint: 1024,
            settings: {
                arrows: false,
                slidesToShow: 4,
                autoplay: false,
                autoplaySpeed: 2000,
                slidesToScroll: 4,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                dots: true,
                arrows: false,
                slidesToShow: 2,
                autoplay: false,
                autoplaySpeed: 2000,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                dots: true,
                arrows: false,
                autoplay: false,
                autoplaySpeed: 2000,
                slidesToShow: 2,
                slidesToScroll: 2
            }
        }

    ]
});

$('.health-checkup-slider').slick({
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 2000,
    speed: 800,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [{
            breakpoint: 1024,
            settings: {
                arrows: false,
                slidesToShow: 4,
                slidesToScroll: 4,
                infinite: true,
                autoplay: false,
                autoplaySpeed: 2000,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                dots: true,
                arrows: false,
                autoplay: false,
                autoplaySpeed: 2000,
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                dots: true,
                autoplay: false,
                autoplaySpeed: 2000,
                arrows: false,
                slidesToShow: 2,
                slidesToScroll: 2
            }
        }

    ]
});

$('.health-risk-slider').slick({
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 2000,
    speed: 800,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [{
            breakpoint: 1024,
            settings: {
                arrows: false,
                slidesToShow: 4,
                slidesToScroll: 4,
                autoplay: false,
                autoplaySpeed: 2000,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                autoplay: false,
                autoplaySpeed: 2000,
                dots: true,
                arrows: false,
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                autoplay: false,
                autoplaySpeed: 2000,
                dots: true,
                arrows: false,
                slidesToShow: 2,
                slidesToScroll: 2
            }
        }

    ]
});

$('.small-test-card-slider').slick({
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 800,
    slidesToShow: 6,
    slidesToScroll: 6,
    responsive: [{
            breakpoint: 1024,
            settings: {
                arrows: false,
                slidesToShow: 3,
                autoplay: true,
                autoplaySpeed: 2000,
                slidesToScroll: 3,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                rows: 2,
                dots: true,
                arrows: false,
                autoplay: true,
                autoplaySpeed: 2000,
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                rows: 2,
                dots: true,
                autoplay: true,
                autoplaySpeed: 2000,
                arrows: false,
                slidesToShow: 2,
                slidesToScroll: 2
            }
        }

    ]
});



$('.form-left-slider').slick({
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [{
            breakpoint: 1024,
            settings: {
                arrows: false,
                slidesToShow: 1,
                autoplay: true,
                autoplaySpeed: 2000,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                dots: true,
                autoplay: true,
                autoplaySpeed: 2000,
                arrows: false,
                slidesToShow: 1,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 480,
            settings: {
                dots: true,
                arrows: false,
                autoplay: true,
                autoplaySpeed: 2000,
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }

    ]
});


$('.leaders-slider').slick({
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 800,
    slidesToShow: 5,
    slidesToScroll: 5,
    responsive: [{
            breakpoint: 1024,
            settings: {
                arrows: false,
                autoplay: true,
                autoplaySpeed: 2000,
                slidesToShow: 5,
                slidesToScroll: 5,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {

                dots: true,
                arrows: false,
                autoplay: true,
                autoplaySpeed: 2000,
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 480,
            settings: {

                dots: true,
                arrows: false,
                autoplay: true,
                autoplaySpeed: 2000,
                slidesToShow: 2,
                slidesToScroll: 2
            }
        }

    ]
});
